import BaseServices from './base';
export default class receivingServices extends BaseServices {
  constructor(props) {
    props.url = '/receiving';
    super(props);
    this.http = props.http;
  }

  serialNumbers = (payload) => {
    const currentWarehouse = window.location.pathname.split('/')[2];
    return this.http.post(`${this.url}/search/${currentWarehouse}/sns`, payload);
  };

  searchSku = (inputSku) => {
    const currentWarehouse = window.location.pathname.split('/')[2];
    return this.http.get(`${this.url}/search-sku/${inputSku}/${currentWarehouse}`);
  };

  validateSn = (payload) => {
    const currentWarehouse = window.location.pathname.split('/')[2];
    return this.http.post(`${this.url}/validate-sn/${currentWarehouse}`, payload);
  };

  createReceiving = (payload) => {
    const currentWarehouse = window.location.pathname.split('/')[2];
    return this.http.post(`${this.url}/create-receiving/${currentWarehouse}`, payload);
  };
}
