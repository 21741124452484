import { mapProductName } from 'utils/mapper';
import BaseStore from './base';
import stores from './index';
class ReceivingStore extends BaseStore {
  constructor(props) {
    super(props);
    this.api = props.api;
  }
  find = (id, showNames = false) => {
    return new Promise((resolve, reject) => {
      this.CRUD.isLoading = true;
      this.api
        .find(id)
        .then((res) => {
          if (showNames) {
            const skus = res.lines.reduce((acc, value) => [...acc, value.sku], []);
            stores.productStore.search(skus).then((products) => {
              this.CRUD.data = res;
              this.CRUD.data.lines = mapProductName(res.lines, products);
              resolve(res);
            });
          } else {
            this.CRUD.data = res;
            resolve(res);
          }
        })
        .catch((err) => reject(err))
        .finally(() => {
          this.CRUD.isLoading = false;
        });
    });
  };

  serialNumbers = (payload) => {
    return this.api.serialNumbers(payload);
  };

  searchSku = (inputSku) => {
    return this.api.searchSku(inputSku);
  };

  validateSn = (payload) => {
    return this.api.validateSn(payload);
  };

  createReceiving = (payload) => {
    return this.api.createReceiving(payload);
  };
}

export default ReceivingStore;
