import { action, makeObservable, observable } from 'mobx';

class AuthStore {
  user = {};
  apiFeatures = null;
  isCreateReceivingAccessible = null;
  accessibleWarehouse = null;

  constructor(props) {
    makeObservable(this, {
      user: observable,
      apiFeatures: observable,
      isCreateReceivingAccessible: observable,
      accessibleWarehouse: observable,
      profile: action,
      setIsCreateReceivingAccessible: action,
      setAccessibleWarehouse: action,
    });

    this.api = props.api;
    this.apiFeatures = props.apiFeatures;
    this.accessibleWarehouse = props.accessibleWarehouse;
    this.isCreateReceivingAccessible = props.createReceivingAccessible;
  }

  getToken = () => {
    return this.api.getToken();
  };
  setToken = (token) => {
    return this.api.setToken(token);
  };
  setAccessibleWarehouse = (apiFeatures) => {
    const result = [];
    for (const item of apiFeatures) {
      switch (item) {
        case 'WAREHOUSE_OPERATION_MDG':
          result.push('MDG');
          break;
        case 'WAREHOUSE_OPERATION_WEST_NETWORK':
          result.push('West Network');
          break;
        case 'WAREHOUSE_OPERATION_FRONTIER_US':
          result.push('Frontier US');
          break;
        case 'WAREHOUSE_OPERATION_PEPLINK_LT':
          result.push('Peplink LT');
          break;
        default:
          break;
      }
    }
    this.accessibleWarehouse = result;
  };
  setIsCreateReceivingAccessible = (value) => {
    this.isCreateReceivingAccessible = value;
  };
  logout = () => {
    return this.api.removeToken();
  };
  login = (form) => {
    return new Promise((resolve, reject) => {
      this.api
        .login(form)
        .then((res) => {
          this.setToken(res.data.access_token);
          resolve(res.data.access_token);
          this.setAccessibleWarehouse(res.data.api_features);
          this.user = { user_name: res.data.user_name };
          this.apiFeatures = res.data.api_features;
        })
        .catch((err) => reject(err.response));
    });
  };

  profile = () => {
    return new Promise((resolve) => {
      this.api.profile().then((res) => {
        this.user = res;
        resolve(res);
      });
    });
  };
}

export default AuthStore;
