import {
  Alert,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { toast } from 'components/common/ReactToast';
import { useEffect, useRef } from 'react';
import React, { useState } from 'react';
import useToggleModal from 'utils/useToggleModal';

const CreateReceivingModal = (props) => {
  const { isOpenReceivingModal, toggleReceivingModal, receivingStore, currentWarehouse } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [validateSnResponse, setValidateSnResponse] = useState({});
  const [confirmReceivingResponse, setConfirmReceivingResponse] = useState({});
  const [skipUploadReceiving, setSkipUploadReceiving] = useState(false);
  const [sns, setSns] = useState('');
  const [confirmFormData, setConfirmFormData] = useState({});
  const [formData, setFormData] = useState({
    sns: [],
    skuMaps: [{}],
    trackingNo: 'ManualReceiving',
    carrier: 'ManualReceiving',
    receivingWarehouse: currentWarehouse,
    remarks: '',
    skipUploadReceiving: skipUploadReceiving,
  });
  const [rows, setRows] = useState([{ sku: '', quantity: '' }]);
  const { isOpen, setIsOpen, toggleModal } = useToggleModal({
    InputSnSkuModal: true,
    InputOtherInfoModal: false,
    ConfirmationModal: false,
    InvalidSnModal: false,
  });

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      skipUploadReceiving: skipUploadReceiving,
      receivingWarehouse: currentWarehouse,
    }));
  }, [skipUploadReceiving, currentWarehouse]);

  useEffect(() => {
    if (sns) {
      const snsArray = sns
        .split('\n')
        .map((i) => i.trim())
        .filter((i) => i !== '');
      setFormData((prevData) => ({ ...prevData, sns: snsArray }));
    } else {
      setFormData((prevData) => ({ ...prevData, sns: [] }));
    }
  }, [sns]);

  useEffect(() => {
    const filledRows = rows.filter((row) => (typeof row?.sku === 'string' && row.sku.trim()) || row.quantity);

    setFormData((prevFormData) => ({
      ...prevFormData,
      skuMaps: filledRows,
    }));
  }, [rows]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const validateSn = async (payload) => {
    return new Promise((resolve) => {
      receivingStore
        .validateSn(payload)
        .then((res) => {
          resolve(res);
          setValidateSnResponse(res);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const searchSku = async (inputSku) => {
    return new Promise((resolve, reject) => {
      receivingStore
        .searchSku(inputSku)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const onConfirmCreateReceiving = async () => {
    let productListMap = new Map();

    confirmFormData.productList?.forEach((item) => {
      const existingItem = productListMap.get(item.sku);

      if (!existingItem) {
        productListMap.set(item.sku, {
          sku: item.sku,
          sns: [...item.sns],
          quantity: item.quantity,
        });
      } else {
        existingItem.sns = [...new Set([...existingItem.sns, ...item.sns])];
        existingItem.quantity += item.quantity;
      }
    });

    return new Promise((resolve, reject) => {
      receivingStore
        .createReceiving({ ...confirmFormData, productList: Array.from(productListMap.values()) })
        .then((res) => {
          resolve(res);
          setConfirmReceivingResponse(res);
        })
        .catch((err) => {
          reject(err.data.errors);
        });
    });
  };

  const handleShowInputSnSkuModal = () => {
    setIsOpen((prevState) => ({
      ...prevState,
      InputSnSkuModal: true,
      InputOtherInfoModal: false,
      ConfirmationModal: false,
      InvalidSnModal: false,
    }));
  };

  const handleShowOtherInfoModal = () => {
    setIsOpen((prevState) => ({
      ...prevState,
      InputSnSkuModal: false,
      InputOtherInfoModal: true,
      ConfirmationModal: false,
      InvalidSnModal: false,
    }));
  };

  const handleShowConfirmationModal = () => {
    setIsOpen((prevState) => ({
      ...prevState,
      InputSnSkuModal: false,
      InputOtherInfoModal: false,
      ConfirmationModal: true,
      InvalidSnModal: false,
    }));
  };

  const handleShowInvalidSnModal = () => {
    setIsOpen((prevState) => ({
      ...prevState,
      InputSnSkuModal: false,
      InputOtherInfoModal: false,
      ConfirmationModal: false,
      InvalidSnModal: true,
    }));
  };

  return (
    <Modal isOpen={isOpenReceivingModal} toggle={toggleReceivingModal} returnFocusAfterClose={false} backdrop={false}>
      <>
        {isOpen.InputSnSkuModal && (
          <InputSnSkuModal
            isOpen={isOpen.InputSnSkuModal}
            onToggle={() => {
              toggleModal('InputSnSkuModal');
              toggleReceivingModal();
            }}
            sns={sns}
            setSns={setSns}
            rows={rows}
            setRows={setRows}
            handleShowOtherInfoModal={handleShowOtherInfoModal}
            handleShowInvalidSnModal={handleShowInvalidSnModal}
            validateSn={validateSn}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            formData={formData}
            validateSnResponse={validateSnResponse}
            setConfirmFormData={setConfirmFormData}
            searchSku={searchSku}
          />
        )}

        {isOpen.InputOtherInfoModal && (
          <InputOtherInfoModal
            isOpen={isOpen.InputOtherInfoModal}
            onToggle={() => {
              toggleModal('InputOtherInfoModal');
              toggleReceivingModal();
            }}
            formData={formData}
            setConfirmFormData={setConfirmFormData}
            handleInputChange={handleInputChange}
            skipUploadReceiving={skipUploadReceiving}
            setSkipUploadReceiving={setSkipUploadReceiving}
            handleShowInputSnSkuModal={handleShowInputSnSkuModal}
            handleShowConfirmationModal={handleShowConfirmationModal}
          />
        )}

        {isOpen.ConfirmationModal && (
          <ConfirmationModal
            isOpen={isOpen.ConfirmationModal}
            setIsOpen={setIsOpen}
            onToggle={() => {
              toggleModal('ConfirmationModal');
              toggleReceivingModal();
            }}
            confirmFormData={confirmFormData}
            handleShowOtherInfoModal={handleShowOtherInfoModal}
            handleShowInvalidSnModal={handleShowInvalidSnModal}
            onConfirmCreateReceiving={onConfirmCreateReceiving}
            toggleReceivingModal={toggleReceivingModal}
          />
        )}

        {isOpen.InvalidSnModal && (
          <InvalidSnModal
            isOpen={isOpen.InvalidSnModal}
            onToggle={() => {
              toggleModal('InvalidSnModal');
              toggleReceivingModal();
            }}
            validateSnResponse={validateSnResponse}
            confirmReceivingResponse={confirmReceivingResponse}
            handleShowInputSnSkuModal={handleShowInputSnSkuModal}
          />
        )}
      </>
    </Modal>
  );
};

const InputSnSkuModal = ({
  isOpen,
  onToggle,
  sns,
  setSns,
  rows,
  setRows,
  isLoading,
  setIsLoading,
  validateSn,
  handleShowOtherInfoModal,
  handleShowInvalidSnModal,
  formData,
  setConfirmFormData,
  searchSku,
}) => {
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState([false]);
  const [fetchedSkus, setFetchedSkus] = useState([[]]);
  const [selectedSkus, setSelectedSkus] = useState(['']);
  const debounceTimeoutRef = useRef(null);

  const handleNext = async (e) => {
    e.preventDefault();

    const emptySkus = rows.filter((row) => {
      return (
        (Array.isArray(row.sku) && row.sku.length === 0) ||
        (typeof row.sku === 'string' && !row.sku.trim() && row.quantity)
      );
    });
    const emptyQtys = rows.filter((row) => !row.quantity && row.sku?.trim());
    const filledRows = rows.filter((row) => (typeof row?.sku === 'string' && row.sku.trim()) || row.quantity);
    const invalidQtys = rows.filter((row) => (!row.quantity || row.quantity <= 0) && row.sku?.trim());

    if (filledRows.length == 0 && sns.trim() === '') {
      setIsShowAlert(true);
      setAlertMessage('Serial Numbers and SKU cannot be both empty');
      return;
    } else if (emptySkus.length > 0) {
      setIsShowAlert(true);
      setAlertMessage('Please fill in the SKU');
      return;
    } else if (emptyQtys.length > 0) {
      setIsShowAlert(true);
      setAlertMessage('Please fill in the Qty');
      return;
    } else if (invalidQtys.length > 0) {
      setIsShowAlert(true);
      setAlertMessage('Qty cannot be less than or equal to 0');
      return;
    }

    setIsLoading(true);

    const response = await validateSn({
      sns: formData.sns,
      skus: formData.skuMaps ? formData.skuMaps.map((item) => item.sku) : [''],
    });
    const hasErrors = response?.items?.reduce((acc, obj) => acc || 'errors' in obj, false);

    setIsLoading(false);

    if (hasErrors) {
      handleShowInvalidSnModal();
    } else {
      const snMaps = response.items.map((item) => {
        const { sn, ...rest } = item;
        return {
          ...rest,
          sns: [sn],
          quantity: 1,
        };
      });

      const combinedSkuMaps = Array.from(
        formData.skuMaps
          ?.reduce((map, item) => {
            if (!map.has(item.sku)) {
              map.set(item.sku, { ...item, sns: [], quantity: +item.quantity });
            } else {
              const existingItem = map.get(item.sku);
              existingItem.quantity += +item.quantity;
            }
            return map;
          }, new Map())
          .values()
      );

      setConfirmFormData((prevData) => ({ ...prevData, productList: [...snMaps, ...combinedSkuMaps] }));

      handleShowOtherInfoModal();
    }
  };

  const handleTableInputChange = (e, index, column) => {
    const newRows = [...rows];
    newRows[index][column] = e.target.value;
    setRows(newRows);
  };

  const addRow = () => {
    setRows([...rows, { sku: '', quantity: '' }]);
    setDropdownOpen((prev) => prev.map(() => false));
    setDropdownOpen((prev) => [...prev, false]);
    setFetchedSkus((prev) => [...prev, []]);
    setSelectedSkus((prev) => [...prev, []]);
  };

  const removeRow = (index) => {
    setRows((prev) => prev.filter((_, rowIndex) => rowIndex !== index));
    setFetchedSkus((prev) => prev.filter((_, skuIndex) => skuIndex !== index));
    setSelectedSkus((prev) => prev.filter((_, skuIndex) => skuIndex !== index));
  };

  const toggleDropdown = (index, sku) => {
    if (sku !== '') {
      setDropdownOpen((prev) => prev.map((value, i) => (i === index ? !value : value)));
      if (!fetchedSkus[index]?.includes(sku)) {
        handleTableInputChange({ target: { value: selectedSkus[index] } }, index, 'sku');
      }
    }
  };

  const handleSelect = (sku, index) => {
    setSelectedSkus((prev) => prev.map((selectedSku, i) => (i === index ? sku : selectedSku)));
    handleTableInputChange({ target: { value: sku } }, index, 'sku');
  };

  const fetchSkus = async (inputSku, index) => {
    if (inputSku !== '') {
      const response = await searchSku(inputSku);
      setFetchedSkus((prev) => prev.map((item, i) => (i === index ? response.skus : item)));
      setDropdownOpen((prev) => prev.map((value, i) => (i === index ? true : value)));
    }
  };

  const handleSkuChange = (e, index, column) => {
    const value = e.target.value;
    handleTableInputChange(e, index, column);

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      fetchSkus(value, index);
    }, 500);
  };

  useEffect(() => {
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      toggle={onToggle}
      backdrop="static"
      returnFocusAfterClose={false}
      fade={false}
      style={{ maxWidth: '550px' }}
      scrollable={true}
    >
      <ModalHeader toggle={onToggle} style={{ fontSize: '15px' }}>
        <Label style={{ fontWeight: 'bold', fontSize: '15px', marginBottom: '0px' }}>Create Receiving - SN / SKU</Label>
      </ModalHeader>
      <hr
        style={{
          height: '1px',
          width: '94%',
          display: 'block',
          margin: '15px auto',
          overflow: 'hidden',
        }}
      />
      <ModalBody
        style={{ minHeight: '350px', maxHeight: '600px', overflowY: 'auto', marginTop: '-15px', marginBottom: '10px' }}
      >
        <Form>
          <FormGroup>
            <Label for="sns" style={{ fontWeight: 'bold', fontSize: '15px' }}>
              Serial Numbers
            </Label>
            <span style={{ fontSize: '12px', marginLeft: '15px', color: 'gray' }}>
              (If no SN, please fill in the SKU and Qty)
            </span>
            <Input type="textarea" id="sns" value={sns} onChange={(e) => setSns(e.target.value)} rows="5" />
          </FormGroup>

          <FormGroup>
            <Table>
              <thead style={{ borderBottom: 'none', borderTop: 'none' }}>
                <tr style={{ marginBottom: '0px', paddingBottom: '0px', borderBottom: 'none', borderTop: 'none' }}>
                  <th style={{ fontSize: '15px', paddingBottom: '0px', paddingLeft: '0px' }}>SKU</th>
                  <th style={{ fontSize: '15px', paddingBottom: '0px' }}>Qty</th>
                  <th style={{ width: '7%', paddingBottom: '0px' }}></th>
                </tr>
              </thead>
              <tbody style={{ marginTop: '0px', paddingTop: '5px', paddingLeft: '0px' }}>
                {rows.map((row, index) => (
                  <tr key={index}>
                    <td style={{ width: '70%', paddingTop: '5px', paddingLeft: '0px' }}>
                      <div style={{ display: 'flex' }}>
                        <UncontrolledDropdown
                          toggle={() => toggleDropdown(index, row.sku)}
                          isOpen={dropdownOpen[index]}
                          direction="down"
                        >
                          <DropdownToggle style={{ padding: '0px 0px' }}>
                            <Input
                              type="text"
                              value={row.sku}
                              onChange={(e) => handleSkuChange(e, index, 'sku')}
                              style={{ width: '350px' }}
                            />
                          </DropdownToggle>
                          <DropdownMenu
                            style={{ width: '100%', maxHeight: '200px', overflowY: 'auto', fontSize: '13px' }}
                            direction="down"
                            modifiers={{
                              preventOverflow: { enabled: false },
                              hide: { enabled: false },
                              flip: { enabled: false },
                            }}
                          >
                            {fetchedSkus[index]?.map((sku, i) => (
                              <div key={i}>
                                <DropdownItem onClick={() => handleSelect(sku, index)}>{sku}</DropdownItem>
                              </div>
                            ))}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </td>
                    <td style={{ width: '20%', paddingTop: '5px' }}>
                      <div style={{ display: 'flex' }}>
                        <Input
                          type="number"
                          value={row.quantity}
                          onChange={(e) => handleTableInputChange(e, index, 'quantity')}
                          style={{ width: '100%' }}
                        />
                      </div>
                    </td>
                    <td style={{ textAlign: 'center', verticalAlign: 'middle', paddingRight: '5px' }}>
                      {rows.length != 1 && (
                        <Button
                          color="white"
                          size="sm"
                          onClick={() => removeRow(index)}
                          style={{ color: 'black', padding: '0', width: '20px', height: '30px' }}
                        >
                          <img
                            src="/images/minus.png"
                            alt="Remove"
                            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                          />
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
                <Button
                  color="white"
                  size="sm"
                  onClick={() => addRow()}
                  style={{ color: 'black', padding: '0', width: '20px', height: '30px' }}
                >
                  <img
                    src="/images/add.png"
                    alt="Add"
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                  />
                </Button>
              </tbody>
            </Table>
          </FormGroup>
        </Form>

        {isShowAlert && <Alert color="danger">{alertMessage}</Alert>}
      </ModalBody>

      <ModalFooter style={{ paddingTop: '5px' }}>
        <Button color="secondary" onClick={() => onToggle()} style={{ color: 'black' }}>
          Cancel
        </Button>
        <Button color="primary" onClick={(e) => handleNext(e)}>
          Next
        </Button>
      </ModalFooter>

      {isLoading && (
        <>
          <Modal isOpen={isLoading}>
            <ModalBody style={{ padding: '20px', textAlign: 'center' }}>Loading...</ModalBody>
          </Modal>
        </>
      )}
    </Modal>
  );
};

const InputOtherInfoModal = ({
  isOpen,
  onToggle,
  formData,
  setConfirmFormData,
  handleInputChange,
  skipUploadReceiving,
  setSkipUploadReceiving,
  handleShowInputSnSkuModal,
  handleShowConfirmationModal,
}) => {
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const handleSubmit = () => {
    if (formData.trackingNo.trim() == '') {
      setIsShowAlert(true);
      setAlertMessage('Tracking No. cannot be empty');
      return;
    } else if (formData.carrier.trim() == '') {
      setIsShowAlert(true);
      setAlertMessage('Carrier cannot be empty');
      return;
    }

    setConfirmFormData((prev) => ({
      ...prev,
      trackingNo: formData.trackingNo,
      carrier: formData.carrier,
      receivingWarehouse: formData.receivingWarehouse,
      skipUploadReceiving: formData.skipUploadReceiving,
      remarks: formData.remarks,
    }));

    handleShowConfirmationModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={onToggle}
      backdrop="static"
      returnFocusAfterClose={false}
      fade={false}
      style={{ maxWidth: '550px' }}
    >
      <ModalHeader toggle={onToggle} style={{ fontSize: '15px' }}>
        <Label style={{ fontWeight: 'bold', fontSize: '15px', marginBottom: '0px' }}>
          Create Receiving - Other Info
        </Label>
      </ModalHeader>
      <hr
        style={{
          height: '1px',
          width: '94%',
          display: 'block',
          margin: '15px auto',
          overflow: 'hidden',
        }}
      />
      <ModalBody style={{ maxHeight: '700px', overflowY: 'auto', marginTop: '-15px' }}>
        <Form>
          <FormGroup>
            <Label for="trackingNo" style={{ fontWeight: 'bold', fontSize: '15px' }}>
              Tracking No.
            </Label>
            <span style={{ fontSize: '12px', marginLeft: '15px', color: 'gray' }}>
              (Please provide tracking number if you have)
            </span>
            <Input type="text" id="trackingNo" value={formData.trackingNo} onChange={handleInputChange} />
          </FormGroup>

          <FormGroup>
            <Label for="carrier" style={{ fontWeight: 'bold', fontSize: '15px' }}>
              Carrier
            </Label>
            <span style={{ fontSize: '12px', marginLeft: '15px', color: 'gray' }}>
              (Please provide carrier if you have)
            </span>
            <Input type="text" id="carrier" value={formData.carrier} onChange={handleInputChange} />
          </FormGroup>

          <FormGroup>
            <Label for="receivingWarehouse" style={{ fontWeight: 'bold', fontSize: '15px' }}>
              Receiving Warehouse
            </Label>
            <Input
              type="text"
              id="receivingWarehouse"
              value={formData.receivingWarehouse}
              onChange={handleInputChange}
              disabled={true}
            />
          </FormGroup>

          {(formData.receivingWarehouse === 'MDG' || formData.receivingWarehouse === 'Frontier US') && (
            <FormGroup style={{ marginBottom: '5px' }}>
              <Input
                type="checkbox"
                checked={skipUploadReceiving}
                onChange={() => setSkipUploadReceiving(!skipUploadReceiving)}
                style={{ marginLeft: '0px' }}
              />
              <Label style={{ marginLeft: '20px', fontSize: '15px', fontWeight: 'bold' }}>
                Skip sending receiving API
              </Label>
            </FormGroup>
          )}

          <FormGroup>
            <Label for="remarks" style={{ fontWeight: 'bold', fontSize: '15px' }}>
              Remarks
            </Label>
            <Input
              type="textarea"
              id="remarks"
              placeholder="Please provide any additional comments or notes"
              value={formData.remarks}
              onChange={handleInputChange}
              rows={3}
            />
          </FormGroup>
        </Form>

        {isShowAlert && <Alert color="danger">{alertMessage}</Alert>}
      </ModalBody>

      <ModalFooter>
        <Button
          id="inputOtherInfoModalBack"
          color="secondary"
          onClick={() => handleShowInputSnSkuModal()}
          style={{ color: 'black' }}
        >
          Back
        </Button>
        <Button color="primary" onClick={() => handleSubmit()}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const ConfirmationModal = ({
  isOpen,
  setIsOpen,
  onToggle,
  confirmFormData,
  handleShowOtherInfoModal,
  handleShowInvalidSnModal,
  onConfirmCreateReceiving,
  toggleReceivingModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onConfirm = async (e) => {
    e.preventDefault();

    const response = await onConfirmCreateReceiving();
    const hasErrors = Object.keys(response).includes('errors');

    if (hasErrors) {
      handleShowInvalidSnModal();
      setIsLoading(false);
    } else {
      setIsOpen((prevState) => ({
        ...prevState,
        InputSnSkuModal: false,
        InputOtherInfoModal: false,
        ConfirmationModal: false,
        InvalidSnModal: false,
      }));
      toggleReceivingModal();
      setIsLoading(false);
      toast.notify({
        type: 'info',
        value: 'Created receiving',
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={onToggle}
      backdrop="static"
      returnFocusAfterClose={false}
      fade={false}
      style={{ maxWidth: '700px' }}
    >
      <ModalHeader toggle={onToggle} style={{ fontSize: '15px' }}>
        <Label style={{ fontWeight: 'bold', fontSize: '15px', marginBottom: '0px' }}>Confirm Create Receiving</Label>
      </ModalHeader>
      <hr
        style={{
          height: '1px',
          width: '94%',
          display: 'block',
          margin: '15px auto',
          overflow: 'hidden',
        }}
      />
      <ModalBody style={{ paddingTop: '0px', paddingBottom: '0px' }}>
        <Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Product Lists</Label>
        <div style={{ maxHeight: '430px', overflowY: 'auto', marginBottom: '15px' }}>
          <Table borderless striped>
            <tbody>
              <tr key="columns">
                <td style={{ fontSize: '13px', fontWeight: 'bold', width: '25%' }}>Serial No.</td>
                <td style={{ fontSize: '13px', fontWeight: 'bold', width: '55%' }}>SKU</td>
                <td style={{ fontSize: '13px', fontWeight: 'bold', width: '20%' }}>Qty</td>
              </tr>

              {confirmFormData?.productList &&
                confirmFormData.productList.map((map, index) => {
                  return (
                    <tr key={index} style={{ fontSize: '13px' }}>
                      <td>{Array.isArray(map.sns) && map.sns.length > 0 ? map.sns : '-'}</td>
                      <td>{map.sku || '-'}</td>
                      <td>{map.quantity || '1'}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>

        <div>
          <p style={{ fontSize: '14px' }}>
            <b>{'Tracking No: '}</b>
            {confirmFormData.trackingNo ? confirmFormData.trackingNo : '-'}
          </p>
          <p style={{ fontSize: '14px' }}>
            <b>{'Carrier: '}</b>
            {confirmFormData.carrier ? confirmFormData.carrier : '-'}
          </p>
          <p style={{ fontSize: '14px' }}>
            <b>{'Receiving Warehouse: '}</b>
            {confirmFormData.receivingWarehouse ? confirmFormData.receivingWarehouse : '-'}
          </p>
          {(confirmFormData.receivingWarehouse === 'MDG' || confirmFormData.receivingWarehouse === 'Frontier US') && (
            <p style={{ fontSize: '14px' }}>
              <b>{'Skip sending receiving API: '}</b>
              {confirmFormData.skipUploadReceiving ? 'Yes' : 'No'}
            </p>
          )}
          <p style={{ fontSize: '14px' }}>
            <b>{'Remarks: '}</b>
            {confirmFormData.remarks ? confirmFormData.remarks : '-'}
          </p>
        </div>
      </ModalBody>

      <ModalFooter style={{ marginTop: '-20px' }}>
        <Button color="secondary" style={{ color: 'black' }} onClick={() => handleShowOtherInfoModal()}>
          Back
        </Button>
        <Button
          color="primary"
          disabled={isLoading}
          onClick={(e) => {
            setIsLoading(true);
            onConfirm(e);
          }}
        >
          Confirm
        </Button>
      </ModalFooter>

      {isLoading && (
        <>
          <Modal isOpen={isLoading}>
            <ModalBody style={{ padding: '20px', textAlign: 'center' }}>Loading...</ModalBody>
          </Modal>
        </>
      )}
    </Modal>
  );
};

const InvalidSnModal = ({
  isOpen,
  onToggle,
  validateSnResponse,
  confirmReceivingResponse,
  handleShowInputSnSkuModal,
}) => {
  let snNotFound = validateSnResponse?.items?.filter((obj) => 'errors' in obj && obj.errors == 'SN_NOT_FOUND');
  let alreadyInStock = validateSnResponse?.items?.filter((obj) => 'errors' in obj && obj.errors == 'ALREADY_IN_STOCK');
  let duplicatedSn = validateSnResponse?.items?.filter((obj) => 'errors' in obj && obj.errors == 'DUPLICATED_SN');
  let invalidSku = validateSnResponse?.items?.filter((obj) => 'errors' in obj && obj.errors == 'INVALID_SKU');
  let processingReceiving = validateSnResponse?.items?.filter(
    (obj) => 'errors' in obj && obj.errors == 'PROCESSING_RECEIVING'
  );

  if (snNotFound?.length == 0) {
    snNotFound = confirmReceivingResponse?.errors?.filter((obj) => 'errors' in obj && obj.errors == 'SN_NOT_FOUND');
  }
  if (alreadyInStock?.length == 0) {
    alreadyInStock = confirmReceivingResponse?.errors?.filter(
      (obj) => 'errors' in obj && obj.errors == 'ALREADY_IN_STOCK'
    );
  }
  if (duplicatedSn?.length == 0) {
    duplicatedSn = confirmReceivingResponse?.errors?.filter((obj) => 'errors' in obj && obj.errors == 'DUPLICATED_SN');
  }
  if (invalidSku?.length == 0) {
    invalidSku = confirmReceivingResponse?.errors?.filter((obj) => 'errors' in obj && obj.errors == 'INVALID_SKU');
  }
  if (processingReceiving?.length == 0) {
    processingReceiving = confirmReceivingResponse?.errors?.filter(
      (obj) => 'errors' in obj && obj.errors == 'PROCESSING_RECEIVING'
    );
  }

  return (
    <Modal isOpen={isOpen} toggle={onToggle} backdrop="static" returnFocusAfterClose={false} fade={false}>
      <ModalHeader>
        <span style={{ fontSize: '16px' }}>Invalid SN / SKU</span>
        <img
          src="/images/warning.png"
          alt="warning"
          style={{ marginBottom: '5px', marginLeft: '7px', width: '13px', height: '13px', objectFit: 'contain' }}
        />
      </ModalHeader>
      <hr
        style={{
          height: '1px',
          width: '94%',
          display: 'block',
          margin: '12px auto',
          PaddingBottom: '0px',
          overflow: 'hidden',
        }}
      />
      <ModalBody style={{ marginBottom: '-10px', paddingTop: '0px' }}>
        <Form>
          {snNotFound?.length > 0 && (
            <FormGroup>
              <div style={{ fontSize: '14px', paddingBottom: '8px', color: 'red' }}>SN Record Not Found.</div>
              <Input
                type="textarea"
                id="recordNotFound"
                placeholder=""
                value={snNotFound
                  .filter((error) => error.sn)
                  .map((error) => error.sn)
                  .join(', ')}
                disabled={true}
                row={1}
                style={{ whiteSpace: 'pre-wrap', resize: 'none' }}
              />
            </FormGroup>
          )}

          {alreadyInStock?.length > 0 && (
            <FormGroup>
              <div style={{ fontSize: '14px', paddingBottom: '8px', color: 'red' }}>
                SN Is Already In Warehouse Stock List.
              </div>
              <Input
                type="textarea"
                id="alreadyInStock"
                placeholder=""
                value={alreadyInStock
                  .filter((error) => error.sn)
                  .map((error) => error.sn)
                  .join(', ')}
                disabled={true}
                style={{ whiteSpace: 'pre-wrap', resize: 'none' }}
              />
            </FormGroup>
          )}

          {duplicatedSn?.length > 0 && (
            <FormGroup>
              <div style={{ fontSize: '14px', paddingBottom: '8px', color: 'red' }}>SN Is Duplicated.</div>
              <Input
                type="textarea"
                id="alreadyInStock"
                placeholder=""
                value={duplicatedSn
                  .filter((error) => error.sn)
                  .map((error) => error.sn)
                  .join(', ')}
                disabled={true}
                style={{ whiteSpace: 'pre-wrap', resize: 'none' }}
              />
            </FormGroup>
          )}

          {invalidSku?.length > 0 && (
            <FormGroup>
              <div style={{ fontSize: '14px', paddingBottom: '8px', color: 'red' }}>SKU Is Invalid.</div>
              <Input
                type="textarea"
                id="alreadyInStock"
                placeholder=""
                value={invalidSku
                  .filter((error) => error.sku)
                  .map((error) => error.sku)
                  .join(', ')}
                disabled={true}
                style={{ whiteSpace: 'pre-wrap', resize: 'none' }}
              />
            </FormGroup>
          )}

          {processingReceiving?.length > 0 && (
            <FormGroup>
              <div style={{ fontSize: '14px', paddingBottom: '8px', color: 'red' }}>SN In Receiving Is Processing.</div>
              <Input
                type="textarea"
                id="alreadyInStock"
                placeholder=""
                value={processingReceiving
                  .filter((error) => error.sn)
                  .map((error) => error.sn)
                  .join(', ')}
                disabled={true}
                style={{ whiteSpace: 'pre-wrap', resize: 'none' }}
              />
            </FormGroup>
          )}

          {snNotFound?.length == 0 &&
            alreadyInStock?.length == 0 &&
            duplicatedSn?.length == 0 &&
            invalidSku?.length == 0 && (
              <FormGroup>
                <div style={{ fontSize: '14px', paddingBottom: '8px', color: 'red' }}>
                  An error occurred when creating receiving.
                </div>
                <Input
                  type="textarea"
                  id="alreadyInStock"
                  placeholder=""
                  value={validateSnResponse?.errors || confirmReceivingResponse?.errors || 'Unexpected error'}
                  disabled={true}
                  style={{ whiteSpace: 'pre-wrap', resize: 'none' }}
                />
              </FormGroup>
            )}
        </Form>
      </ModalBody>

      <ModalFooter>
        <Button color="secondary" style={{ color: 'black' }} onClick={() => handleShowInputSnSkuModal()}>
          Back
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateReceivingModal;
