import { formatDate, formatDateTime } from 'utils/formatters';
import { inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { ROUTE, SHIPMENT_STATUS } from 'definitions';
import classNames from 'classnames';
import confirm from 'components/common/ConfirmManager';
import ConfirmDialog from 'components/common/ConfirmDialog';
import isEqual from 'lodash/isEqual';
import qs from 'query-string';
import React from 'react';
import ReactTable from 'components/common/ReactTable';
import SearchForm from 'components/common/SearchForm';
import StatusFilter from 'components/common/StatusFilter';
import TableStatus from 'components/common/TableStatus';

const Orders = (props) => {
  const {
    history,
    orderStore: {
      Table: { data },
    },
    orderStore,
    location,
    authStore,
  } = props;

  const currentWarehouse = window.location.pathname.split('/')[2];
  const { status = '' } = qs.parse(location.search);
  const [rowSelected, setRowSelected] = React.useState([]);
  const [tableFilters, setTableFilters] = React.useState({
    status,
    pageIndex: 0,
    pageSize: 10,
  });
  const [isFullyLoaded, setIsFullyLoaded] = React.useState(false);

  React.useEffect(() => {
    setTableFilters((prevState) => ({ ...prevState, pageIndex: 0, status }));
  }, [status]);

  const fetchData = React.useCallback((options) => {
    setTableFilters((prevState) => ({ ...prevState, ...options }));
  }, []);

  const getData = React.useCallback(async () => {
    try {
      await orderStore.search({
        page_num: tableFilters.pageIndex,
        page_size: tableFilters.pageSize,
        sorting: tableFilters.sorting,
        ...(status && { status }),
        ...(tableFilters.search_string && { search_string: tableFilters.search_string }),
      });
    } catch (err) {
      if (
        err.data.errors === 'Invalid path name' ||
        err.data.errors === 'You are not allowed to access this resource'
      ) {
        history.push('/notFound');
      }
    }
  }, [
    orderStore,
    status,
    tableFilters.pageIndex,
    tableFilters.pageSize,
    tableFilters.search_string,
    tableFilters.sorting,
    history,
  ]);

  React.useEffect(() => {
    const fetchData = async () => {
      setIsFullyLoaded(false);
      if (currentWarehouse !== undefined && currentWarehouse !== '' && authStore.user.user_name != undefined) {
        await getData();
        setIsFullyLoaded(true);
      }
    };

    fetchData();
  }, [getData, currentWarehouse, authStore.user.user_name]);

  const tableData = React.useMemo(() => {
    return data.items.map((i) => {
      return { ...i, disabled: i.status !== SHIPMENT_STATUS.PENDING_CANCEL.value };
    });
  }, [data.items]);

  const handleSelectionChange = React.useCallback(
    (selected) => {
      if (!isEqual(selected, rowSelected)) {
        setRowSelected(selected);
      }
    },
    [rowSelected]
  );

  const columns = React.useMemo(() => {
    const showConfirm = (ids) => {
      confirm.show({
        props: {
          message: 'The relevant orders will be cancelled',
          onConfirm: () => {
            return new Promise((resolve, reject) => {
              orderStore
                .cancelOrder(ids)
                .then(() => {
                  getData();
                  resolve();
                })
                .catch((err) => reject(err.data.errors.message));
            });
          },
        },
        Component: ConfirmDialog,
      });
    };

    const handleCancelOrder = (e, item) => {
      e.stopPropagation();
      showConfirm({ ids: [item.id] });
    };

    const handleBulkCancelOrder = () => {
      const ids = rowSelected.reduce((acc, val) => [...acc, val.id], []);
      showConfirm({ ids });
    };

    return [
      {
        Header: 'Fulfilment ID',
        accessor: 'id',
        width: 4,
        Cell: ({ cell: { row } }) => row.original.id.substring(0, 8).toUpperCase(),
      },
      ...(currentWarehouse === 'mdg'
        ? [
            {
              Header: 'MDG Ref. ID',
              accessor: 'ref_id',
              width: 4,
            },
          ]
        : []),
      {
        Header: 'Order No.',
        accessor: 'order_id',
        width: 4,
      },
      {
        Header: 'Customer',
        accessor: 'ship_to_company',
      },
      {
        Header: 'Order Qty',
        accessor: 'total_quantity',
        className: 'text-center',
        width: 4,
      },
      {
        Header: 'Fulfilled Qty',
        accessor: 'shipped_quantity',
        className: 'text-center',
        width: 4,
      },
      {
        Header: 'Expected Ship Date',
        accessor: 'expected_delivery_date',
        className: 'text-center',
        width: 8,
        Cell: ({ cell: { value } }) => formatDate(value),
      },
      {
        Header: 'Status',
        accessor: 'status',
        className: 'text-center',
        width: 10,
        Cell: ({ cell: { value } }) => <TableStatus type={value} />,
      },
      {
        Header: 'Created On',
        accessor: 'created_date',
        width: 8,
        Cell: ({ cell: { value } }) => formatDateTime(value),
      },
      {
        Header: () => (
          <React.Fragment>
            {!!rowSelected.length && (
              <button className="btn-icon" onClick={handleBulkCancelOrder}>
                <i className="material-icons md-dark md-24 p-0">delete</i>
              </button>
            )}
          </React.Fragment>
        ),
        className: 'text-right',
        accessor: 'row-action',
        width: 1,
        sortable: false,
        Cell: ({ cell: { row } }) => (
          <span
            className={classNames('d-flex justify-content-end align-items-center', {
              'hover-effect': !row.original.disabled,
            })}
          >
            {!row.original.disabled && (
              <span className="row-actions position-absolute text-right no-row-click">
                <button className="btn-icon" onClick={(e) => handleCancelOrder(e, row.original)}>
                  <i className="material-icons md-dark md-24 p-0">delete</i>
                </button>
              </span>
            )}
          </span>
        ),
      },
    ];
  }, [currentWarehouse, getData, orderStore, rowSelected]);

  const filterButtons = [
    SHIPMENT_STATUS.ALL,
    SHIPMENT_STATUS.PENDING_SHIPMENT,
    SHIPMENT_STATUS.WORK_IN_PROGRESS,
    SHIPMENT_STATUS.PARTIAL_SHIPPED,
    SHIPMENT_STATUS.PENDING_CANCEL,
    SHIPMENT_STATUS.CANCELLED,
    SHIPMENT_STATUS.COMPLETED,
  ];
  const isMulti = tableFilters.status === '' || tableFilters.status === SHIPMENT_STATUS.PENDING_CANCEL.value;

  const getMeaning = (currentWarehouse) => {
    switch (currentWarehouse) {
      case 'mdg':
        return 'MDG';
      case 'west-network':
        return 'West Network';
      case 'frontier-us':
        return 'Frontier US';
      case 'peplink-lt':
        return 'Peplink LT';
    }
    return currentWarehouse;
  };

  return (
    <div className="container-fluid p-md-5 p-3">
      <div className="breadcrumb">
        <h6>
          <Link to={`/inventory/${currentWarehouse}`}>Home</Link> {'>'} Orders
        </h6>
      </div>

      <div className="head-wrapper">
        <div className="head-title">
          <h4>
            <i className="material-icons md-dark md-36 pl-0">list_alt</i>
            Orders ({getMeaning(currentWarehouse)})
          </h4>
        </div>
      </div>
      <div className="search mt-4">
        <SearchForm
          onChange={(value) =>
            setTableFilters((prevState) => ({ ...prevState, search_string: value.toLowerCase(), pageIndex: 0 }))
          }
        />
      </div>
      <div className="filter-common my-3">
        {filterButtons.map((i, key) => (
          <StatusFilter
            key={`status-filter-${key}`}
            state={status}
            type={i}
            pathname={`/${ROUTE.ORDERS}/${currentWarehouse}`}
          />
        ))}
      </div>

      <div id="count-records" className={classNames('mt-3 mb-4', { invisible: !isFullyLoaded })}>
        {`${data.total_count} Records Found`}
      </div>
      <ReactTable
        data={tableData}
        columns={columns}
        className="table-orders"
        options={{
          isLoading: !isFullyLoaded,
          fetchData,
          filters: tableFilters,
          pageCount: data.total_page,
          isMulti: isMulti,
          disabledProp: 'disabled',
          disabledShowOnly: true,
          onSelectionChanged: handleSelectionChange,
          onRowClick: (row) => history.push(`/${ROUTE.ORDERS}/${currentWarehouse}/${row.original.id}`),
        }}
      />
    </div>
  );
};

export default inject(({ orderStore, authStore }) => ({ orderStore, authStore }))(observer(Orders));
