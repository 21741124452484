import { downloadCSV } from 'utils/downloadCSV';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import invAvailable from 'images/inv_available.svg';
import invProduct from 'images/inv_product.svg';
import invReserved from 'images/inv_reserved.svg';
import invTransit from 'images/inv_transit.svg';
import moment from 'moment';
import Panel from './Panel';
import qs from 'query-string';
import React from 'react';
import ReactDropdown from 'components/common/ReactDropdown';
import ReactTable from 'components/common/ReactTable';
import SearchForm from 'components/common/SearchForm';
import SerialModal from './SerialModal';
import useToggleModal from 'utils/useToggleModal';

const Inventory = (props) => {
  const {
    inventoryStore: {
      Table: { data = {} },
    },
    inventoryStore,
    location,
    history,
    authStore,
  } = props;

  const currentWarehouse = window.location.pathname.split('/')[2];
  const { filter = '' } = qs.parse(location.search);
  const [selectedSKU, setSelectedSKU] = React.useState();
  const [summary, setSummary] = React.useState({});
  const [tableFilters, setTableFilters] = React.useState({
    filter,
    pageIndex: 0,
    pageSize: 10,
  });
  const { isOpen, toggleModal } = useToggleModal({
    serialModal: false,
  });
  const [isFullyLoaded, setIsFullyLoaded] = React.useState(false);

  const getReport = (data, type) => {
    switch (type) {
      case 'serial_number_list':
        return {
          filename: 'Serial_Number_List',
          headers: {
            sku: 'SKU',
            sn: 'Serial Number',
            stock_in_date: 'Stock In Date',
          },
          data: data.items
            .filter((i) => i.items.length)
            .reduce(
              (acc, value) => [
                ...acc,
                ...value.items.reduce((a, { sn, stock_in_date }) => [...a, { sku: value.sku, sn, stock_in_date }], []),
              ],
              []
            ),
        };
      default:
        return {
          filename: 'Inventory_Report',
          headers: {
            sku: 'SKU',
            quantity: 'Quantity',
          },
          data: data.items.map(({ sku, quantity }) => ({
            sku,
            quantity,
          })),
        };
    }
  };
  const exportCSV = (type) => {
    inventoryStore.inventory().then((res) => {
      const { data, filename, headers } = getReport(res, type);
      const timestamp = moment().format('YYYYMMDD');
      data.unshift(headers);
      downloadCSV(data, `${filename}_${timestamp}.csv`);
    });
  };

  const getData = React.useCallback(async () => {
    try {
      await inventoryStore.search({
        page_num: tableFilters.pageIndex,
        page_size: tableFilters.pageSize,
        sorting: tableFilters.sorting,
        ...(tableFilters.filter && { filter: tableFilters.filter }),
        ...(tableFilters.search_string && { search_string: tableFilters.search_string }),
      });
    } catch (err) {
      if (
        err.data.errors === 'Invalid path name' ||
        err.data.errors === 'You are not allowed to access this resource'
      ) {
        history.push('/notFound');
      }
    }
  }, [
    inventoryStore,
    tableFilters.filter,
    tableFilters.pageIndex,
    tableFilters.pageSize,
    tableFilters.search_string,
    tableFilters.sorting,
    history,
  ]);

  React.useEffect(() => {
    const fetchData = async () => {
      setIsFullyLoaded(false);
      if (currentWarehouse !== undefined && currentWarehouse !== '' && authStore.user.user_name != undefined) {
        try {
          const [summary] = await Promise.all([inventoryStore.summary(), getData()]);
          setSummary(summary);
          setIsFullyLoaded(true);
        } catch (err) {
          if (
            err.data.errors === 'Invalid path name' ||
            err.data.errors === 'You are not allowed to access this resource'
          ) {
            history.push('/notFound');
          }
        }
      }
    };

    fetchData();
  }, [getData, currentWarehouse, authStore.user.user_name, inventoryStore, history]);

  React.useEffect(() => {
    setTableFilters((prevState) => ({ ...prevState, filter, pageIndex: 0 }));
  }, [filter]);

  const fetchData = React.useCallback((options) => {
    setTableFilters((prevState) => ({ ...prevState, ...options }));
  }, []);

  const showSerialModal = (sku) => {
    setSelectedSKU(sku);
    toggleModal('serialModal');
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Product Code',
        accessor: 'sku',
        width: 10,
      },
      {
        Header: 'Product Name',
        accessor: 'productName',
        width: 30,
      },
      {
        Header: 'Available',
        accessor: 'available',
        className: 'text-center collapsed',
      },
      {
        Header: 'Reserved',
        accessor: 'reserved',
        className: 'text-center collapsed',
      },
      {
        Header: 'Transit',
        accessor: 'transit',
        className: 'text-center collapsed',
      },
    ],

    []
  );

  const searchFilterOptions = [
    {
      label: 'Products',
      title: 'Products in Inventory',
      value: '',
      image: invProduct,
      count: summary.total_available + summary.total_reserved || 0,
    },
    {
      label: 'Available',
      title: 'Available Products',
      value: 'available',
      image: invAvailable,
      count: summary.total_available,
    },
    {
      label: 'Reserved',
      title: 'Reserved Products',
      value: 'reserved',
      image: invReserved,
      count: summary.total_reserved,
    },
    {
      label: 'Transit',
      title: 'Products in Transit',
      value: 'transit',
      image: invTransit,
      count: summary.total_transit,
    },
  ];

  const getMeaning = (currentWarehouse) => {
    switch (currentWarehouse) {
      case 'mdg':
        return 'MDG';
      case 'west-network':
        return 'West Network';
      case 'frontier-us':
        return 'Frontier US';
      case 'peplink-lt':
        return 'Peplink LT';
    }
    return currentWarehouse;
  };

  return (
    <>
      <div className="container-fluid p-md-5 p-3">
        <div className="head-title">
          <h4>
            <i className="material-icons md-dark md-36 pl-0" style={{ marginTop: '-5px' }}>
              store
            </i>
            Inventory ({getMeaning(currentWarehouse)})
          </h4>
        </div>
        <div className="search-selective  mt-4">
          <SearchForm
            onChange={(value) => setTableFilters((prevState) => ({ ...prevState, search_string: value, pageIndex: 0 }))}
          >
            <div className="input-group-prepend">
              <ReactDropdown
                options={searchFilterOptions}
                value={searchFilterOptions.find((i) => i.value === tableFilters.filter)}
                onChange={(selected) => setTableFilters((prevState) => ({ ...prevState, filter: selected.value }))}
              />
            </div>
          </SearchForm>
          <div className="input-group mb-3"></div>
        </div>
        <div className="filter-inventory  mt-3">
          <div className="row">
            {searchFilterOptions.map((i, key) => (
              <Panel key={`panel-${key}`} item={i} filter={tableFilters.filter} />
            ))}
          </div>
        </div>
        <div className="table-inventory">
          <div className="d-flex mt-3 mb-4 align-items-center">
            <div id="count-records" className={classNames({ invisible: !isFullyLoaded })}>
              {`${data.total_count} Records Found`}
            </div>
            <UncontrolledDropdown className="ml-auto">
              <DropdownToggle
                color="default"
                id="dropdown-action"
                className="btn btn-dropdown btn-small dropdown-toggle d-flex align-items-center h-100 shadow-none"
                aria-haspopup={true}
                aria-expanded="false"
              >
                <i className="material-icons md-dark md-16 pl-0 pr-1">save_alt</i>
                Download
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={exportCSV}>Inventory Report</DropdownItem>
                <DropdownItem onClick={() => exportCSV('serial_number_list')}>Serial Number List</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
          {/* <ReactTable
            data={data.items}
            columns={columns}
            controlled
            options={{
              isLoading,
              fetchData: fetchData,
              filters: tableFilters,
              pageCount: data.total_page,
              onRowClick: (row) => showSerialModal(row.values.sku),
            }}
          /> */}
          <ReactTable
            data={data.items}
            columns={columns}
            options={{
              isLoading: !isFullyLoaded,
              fetchData,
              filters: tableFilters,
              pageCount: data.total_page,
              onRowClick: (row) => showSerialModal(row.values.sku),
            }}
          />
        </div>
      </div>
      {isOpen.serialModal && (
        <SerialModal sku={selectedSKU} history={history} onToggle={() => toggleModal('serialModal')} />
      )}
    </>
  );
};

export default inject('inventoryStore', 'authStore')(observer(Inventory));
